import { useMainStore } from '../stores/main';
import { Abi } from '../contracts';
import IERC20Json from '../../build/contracts/IERC20.json';
import ERC20Json from '../../build/contracts/ERC20.json';
import BigNumber from 'bignumber.js';
import { nullAddress } from './web3';

export async function getAllowance(tokenAddress: string): Promise<BigNumber> {
  const mainStore = useMainStore();
  const web3 = mainStore.web3;
  if(!web3) return new BigNumber(0);
  const erc20Contract = new web3.eth.Contract(IERC20Json.abi as Abi, tokenAddress);
  const allowance = await erc20Contract.methods
    .allowance(mainStore.defaultAccount, mainStore.contracts().Launchpad.options.address)
    .call({from: mainStore.defaultAccount});
  return new BigNumber(allowance);
}

export async function approveERC20(tokenAddress: string, amount: string) {
  const mainStore = useMainStore();
  const web3 = mainStore.web3;
  if(!web3) return;
  const erc20Contract = new web3.eth.Contract(IERC20Json.abi as Abi, tokenAddress);
  const launchpadContractAddress = mainStore.contracts().Launchpad.options.address;
  const allowance = await getAllowance(tokenAddress);
  if(new BigNumber(amount).lte(allowance)) {
    return;
  }
  await erc20Contract.methods.approve(launchpadContractAddress, amount).send({from: mainStore.defaultAccount});
}

export async function getTokenBalance(tokenAddress: string): Promise<BigNumber> {
  const mainStore = useMainStore();
  const web3 = mainStore.web3;
  if(!web3) return new BigNumber(0);
  const erc20Contract = new web3.eth.Contract(IERC20Json.abi as Abi, tokenAddress);
  const balance = await erc20Contract.methods.balanceOf(mainStore.defaultAccount).call({from: mainStore.defaultAccount});
  return new BigNumber(balance);
}

export async function getTokenSymbol(tokenAddress: string): Promise<string> {
  if(tokenAddress === nullAddress) return '';
  const mainStore = useMainStore();
  const web3 = mainStore.web3;
  if(!web3) return '';
  const erc20Contract = new web3.eth.Contract(ERC20Json.abi as Abi, tokenAddress);
  return await erc20Contract.methods.symbol().call({from: mainStore.defaultAccount});
}
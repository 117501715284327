
import { useMainStore } from '../../stores/main';
import { useLaunchpadStore } from '../../stores/launchpad';
import { mapState, mapActions } from 'pinia';
import { defineComponent } from 'vue';

interface Data {
  showMenuModal: boolean;
  showBuySkillModal: boolean;
  isConnecting: boolean;
}

export default defineComponent({
  data() {
    return {
      showMenuModal: false,
      showBuySkillModal: false,
      isConnecting: false
    } as Data;
  },

  computed: {
    ...mapState(useMainStore, ['defaultAccount', 'isWrongNetwork', 'isWalletConnected', 'getExchangeUrl', 'getExchangeTransakUrl']),
    connectButtonText(): string {
      if(this.isWrongNetwork) return 'Wrong Network';
      if(this.isConnecting) return 'Connecting';
      const address = this.isWalletConnected ? this.defaultAccount : null;
      return (address && `${address.slice(0, 3)}...${address.slice(address.length-4, address.length)}`) ?? 'Connect';
    },
    homeViewActive(): boolean {
      return this.$route.path.toString() === '/';
    },
    claimViewActive(): boolean {
      return this.$route.path.toString() === '/claim';
    },
    projectsViewActive(): boolean {
      return this.$route.path.toString() === '/projects';
    },
  },

  methods: {
    ...mapActions(useMainStore, ['disconnect', 'configureMetaMask']),
    ...mapActions(useLaunchpadStore, ['fetchVestings']),
    async toggleConnect(): Promise<void> {
      try {
        this.isConnecting = true;
        if(this.isWalletConnected && !this.isWrongNetwork) {
          await this.disconnect();
        }
        else {
          await this.configureMetaMask();
        }
      }
      finally {
        this.isConnecting = false;
      }

      await this.fetchVestings();
    },

    loadBlockpassWidget() {
      const blockpass = new (window as any).BlockpassKYCConnect('riveted_games_1881f');
      blockpass.startKYCConnect();
    },


    showMenu() {
      this.showMenuModal = true;
      setTimeout(() => this.loadBlockpassWidget(), 200);
    }
  },

  mounted() {
    this.loadBlockpassWidget();
  }
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavbarMain = _resolveComponent("NavbarMain")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterMain = _resolveComponent("FooterMain")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, { "theme-overrides": _ctx.themeOverrides }, {
    default: _withCtx(() => [
      _createVNode(_component_NavbarMain, { class: "-mb-24" }),
      _createVNode(_component_router_view),
      _createVNode(_component_FooterMain, { class: "p-10 -mt-1" })
    ]),
    _: 1
  }, 8, ["theme-overrides"]))
}
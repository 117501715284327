
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    loadBlockpassWidget() {
      const blockpass = new (window as any).BlockpassKYCConnect('riveted_games_1881f', {
        elementId: 'blockpass-kyc-connect-footer'
      });

      blockpass.startKYCConnect();
    },
  },

  mounted() {
    // this.loadBlockpassWidget();
  }
});

export function secondsToDDHHMMSS(sec: number) {
  const days = Math.floor(sec/(60*60*24));
  const hours = Math.floor(sec/(60*60)%24);
  const minutes = Math.floor(sec/(60))%60;
  const seconds = sec%60;

  return `${days !== 0 && ('0' + days).slice(-2) + 'D '}` + `${hours !== 0 && ('0' + hours).slice(-2) + 'H '}` +
    `${minutes !== 0 &&('0' + minutes).slice(-2) + 'M '}` + `${seconds !== 0 && ('0' + seconds).slice(-2) + 'S'}`;
}

export function secondsToDD(sec: number) {
  const days = Math.floor(sec/(60*60*24));
  return `${('0' + days).slice(-2)}`;
}

export function secondsToDays(sec: number) {
  return Math.floor(sec/(60*60*24));
}

export function secondsToHH(sec: number) {
  const hours = Math.floor(sec/(60*60)%24);
  return `${('0' + hours).slice(-2)}`;
}

export function secondsToMM(sec: number) {
  const minutes = Math.floor(sec/(60))%60;
  return `${('0' + minutes).slice(-2)}`;
}

export function secondsToSS(sec: number) {
  const seconds = sec%60;
  return `${('0' + seconds).slice(-2)}`;
}

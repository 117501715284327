
import { defineComponent, PropType } from 'vue';
import { mapState, mapActions } from 'pinia';
import { useLaunchpadStore } from '../../stores/launchpad';
import { ILaunch } from '../../interfaces/ILaunch';
import { fromWeiEther } from '../../utils/web3';
import { IPeriodicVestingInfo } from '@/interfaces/IVestingInfo';
import { getImg } from '@/utils/common';

interface Data {
  isClaiming: boolean;
}

export default defineComponent({
  props: {
    vestingInfo: {
      type: Object as PropType<IPeriodicVestingInfo>,
      required: true
    }
  },

  data() {
    return {
      isClaiming: false
    } as Data;
  },

  computed: {
    ...mapState(useLaunchpadStore, ['getLaunch']),
    claimStartDate(): string {
      if(!this.vestingInfo.vestingStartTimestamp) return '';
      const date = new Date(this.vestingInfo.vestingStartTimestamp*1000);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    launch(): ILaunch {
      return this.getLaunch(this.vestingInfo.launchId);
    },
  },

  methods: {
    getImg,
    ...mapActions(useLaunchpadStore, ['claimTokensPeriodic']),
    fromWeiEther,

    async onClaimTokens() {
      try {
        this.isClaiming = true;
        await this.claimTokensPeriodic(this.vestingInfo.launchId, this.vestingInfo.vestingId);
      }
      finally {
        this.isClaiming = false;
      }
    }
  }
});

import BigNumber from 'bignumber.js';

export const nullAddress = '0x0000000000000000000000000000000000000000';

export const fromWeiEther = (value: string | BigNumber): string => {
  return new BigNumber(value).div('1000000000000000000').toString();
};

export const toWeiEther = (value: string | BigNumber): string => {
  return new BigNumber(value).times('1000000000000000000').toFixed();
};


import { defineComponent } from 'vue';
import LaunchpadTop from '../modules/LaunchpadTop.vue';
import ApplyForIncubationCard from '../modules/ApplyForIncubationCard.vue';
import OurTeamCard from '../modules/OurTeamCard.vue';
import HowToParticipate from '../modules/HowToParticipate.vue';
import UpcomingIdos from '../info/UpcomingIdos.vue';


export default defineComponent({
  components: {
    LaunchpadTop,
    ApplyForIncubationCard,
    OurTeamCard,
    HowToParticipate,
    UpcomingIdos,
  },
});

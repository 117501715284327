import { defineStore } from 'pinia';
import BigNumber from 'bignumber.js';
import { useMainStore } from './main';

interface CryptoBladesState {
  mainStore: any;
  unclaimedSkillBalance: BigNumber;
}

export const useCryptoBladesStore = defineStore('cryptoblades', {
  state: () => ({
    mainStore: useMainStore(),
    unclaimedSkillBalance: new BigNumber(0)
  }) as CryptoBladesState,

  actions: {
    async fetchUnclaimedSkillBalance() {
      const { CryptoBlades } = this.mainStore.contracts();
      if(!CryptoBlades || !this.mainStore.isWalletConnected) return;

      this.unclaimedSkillBalance = new BigNumber(await CryptoBlades.methods.getTokenRewards().call({from: this.mainStore.defaultAccount}));
    }
  }
});
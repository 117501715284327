
import { defineComponent } from 'vue';
import { useLaunchpadStore } from '../../stores/launchpad';
import { mapState } from 'pinia';

export default defineComponent({
  computed: {
    ...mapState(useLaunchpadStore, ['featuredLaunchId']),
  },

  data() {
    return {
      show: false
    };
  }

});

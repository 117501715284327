import { createRouter, createWebHashHistory } from 'vue-router';
import LaunchpadView from './components/views/LaunchpadView.vue';
import ClaimView from './components/views/ClaimView.vue';
import ProjectsView from './components/views/ProjectsView.vue';
import LaunchDetailsView from './components/views/LaunchDetailsView.vue';
import PrivacyPolicyView from './components/views/PrivacyPolicyView.vue';
import TermsAndConditionsView from './components/views/TermsAndConditionsView.vue';

export default function getRouter() {
  const router = createRouter({
    history: createWebHashHistory(),
    routes: [
      {path: '/', name: 'Launchpad', component: LaunchpadView},
      {path: '/projects', name: 'Projects', component: ProjectsView},
      {path: '/claim', name: 'Claim', component: ClaimView},
      {path: '/launch/:id', name: 'LaunchDetails', component: LaunchDetailsView},
      {path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicyView},
      {path: '/terms-and-conditions', name: 'TermsAndConditions', component: TermsAndConditionsView},
    ],
    scrollBehavior(to) {
      // always scroll to top
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth',
          top: 15
        };
      }

      return { top: 0 };
    },
  });

  return router;
}
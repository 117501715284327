
import { defineComponent } from 'vue';
import { chainLogo } from '../../utils/chain-logo';
import ClaimList from '../info/ClaimList.vue';
import ApplyForIncubationCard from '../modules/ApplyForIncubationCard.vue';
import { useMainStore } from '../../stores/main';
import { mapState, mapActions } from 'pinia';


export default defineComponent({
  components: {
    ClaimList,
    ApplyForIncubationCard
  },
  data() {
    return {
      chainLogo,
      claimOption: 0
    };
  },

  computed: {
    ...mapState(useMainStore, ['currentChain']),
  },

  methods: {
    ...mapActions(useMainStore, ['setCurrentChain']),
    setClaimOption(claimOption: number) {
      this.claimOption = claimOption;
    },
    async setChain(currentChain: string) {
      await this.setCurrentChain(currentChain);
    }
  }
});


import bscLogo from '../assets/chains/bsc.png';
import avalancheLogo from '../assets/chains/avalanche.svg';
import polygonLogo from '../assets/chains/polygon.svg';
import hecoLogo from '../assets/chains/heco.svg';
import oecLogo from '../assets/chains/oec.svg';
import auroraLogo from '../assets/chains/aurora.svg';
import skaleLogo from '../assets/chains/skale.png';

export function chainLogo(chain: string): string {
  switch(chain) {
  case 'BNB':
    return bscLogo;
  case 'OEC':
    return oecLogo;
  case 'AVAX':
  case 'AVALANCHE':
    return avalancheLogo;
  case 'POLYGON':
    return polygonLogo;
  case 'HECO':
    return hecoLogo;
  case 'AURORA':
    return auroraLogo;
  case 'SKALE':
    return skaleLogo;
  default:
    return bscLogo;
  }
}
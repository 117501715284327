
import { defineComponent } from 'vue';
import ProjectCard from './ProjectCard.vue';
import { useLaunchpadStore } from '../../stores/launchpad';
import { mapState } from 'pinia';

export default defineComponent({
  components: {
    ProjectCard
  },

  computed: {
    ...mapState(useLaunchpadStore, ['launches', 'upcomingLaunchIds']),
  }
});

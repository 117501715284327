import { Contracts, getConfigValue } from '@/contracts';
import { nullAddress } from '@/utils/web3';
import { defineStore } from 'pinia';
import Web3 from 'web3';
import { setUpContracts } from '../contracts';
import config from '../../app-config.json';
import { useLaunchpadStore } from './launchpad';

const transakAPIURL = process.env.VUE_APP_TRANSAK_API_URL || 'https://staging-global.transak.com';
const transakAPIKey = process.env.VUE_APP_TRANSAK_API_KEY || '90167697-74a7-45f3-89da-c24d32b9606c';

interface IState {
  web3: Web3 | null;
  defaultAccount: string | null;
  currentNetworkId: number | null;
  currentChain: string | null;
  contracts: () => Contracts;
  isWrongNetwork: boolean;
}

interface Chain {
  chains: Record<string, Record<string, any>>;
}

interface Config {
  environments: Record<string, Chain>;
}

export const defaultCallOptions = (state: IState) => ({ from: state.defaultAccount });

export const useMainStore = defineStore('main', {
  state: () => ({
    web3: null,
    defaultAccount: nullAddress,
    currentNetworkId: null,
    currentChain: localStorage.getItem('currentChain'),
    contracts: null!,
    isWrongNetwork: false
  }) as IState,

  getters: {
    isWalletConnected: (state) => {
      return state.defaultAccount && state.defaultAccount !== nullAddress;
    },
    getExchangeUrl() {
      return getConfigValue('exchangeUrl');
    },
    getExchangeTransakUrl() {
      const currencyNetwork = getConfigValue('currencyNetwork') || 'BNB';
      const currencyDefault = getConfigValue('currency') || 'BNB';
      const currencyList = getConfigValue('currencyTransak') || 'BNB,BUSD';

      const urlCC = 'defaultCryptoCurrency=' + currencyDefault;
      const urlCCL = 'cryptoCurrencyList=' + currencyList;

      //Transak is not available for Huobi & OKEX
      if(currencyNetwork === 'Huobi' || currencyNetwork === 'OKEx') return;

      return transakAPIURL + '/?apiKey=' + transakAPIKey + '&' + urlCC + '&' + urlCCL;
    },
  },
  // optional actions
  actions: {
    async initialize(web3: Web3) {
      this.web3 = web3;
      await this.checkIsCorrectNetwork();
      const contracts = await setUpContracts(web3);
      this.contracts = () => contracts;

      (window as any).ethereum?.on('chainChanged', async () => {
        console.log('chain changed');
        await this.checkIsCorrectNetwork();
        try {
          const contracts = await setUpContracts(web3);
          this.contracts = () => contracts;
          await useLaunchpadStore().fetchAllLaunchDetails();
          await useLaunchpadStore().fetchVestings();
        }
        catch (err) {
          console.error('Check if you are connected to the correct network!');
          console.error(err);
        }
      });

      (window as any).ethereum?.on('accountsChanged', async (accounts: string[]) => {
        this.defaultAccount = accounts[0];
      });
    },

    async setCurrentChain(chainName: string) {
      const prevCurrentChain = localStorage.getItem('currentChain') || 'BNB';
      localStorage.setItem('currentChain', chainName);
      const success = await this.configureMetaMask();
      if(!success) {
        localStorage.setItem('currentChain', prevCurrentChain);
        return;
      }
      this.currentChain = chainName;
    },

    async checkIfConnected() {
      if(!this.web3) return;
      const accounts = await this.web3.eth.requestAccounts();
      console.log(accounts);
      if (accounts.length !== 0) {
        this.defaultAccount = accounts[0];
        return 1;
      } else {
        return 0;
      }
    },

    async disconnect() {
      this.defaultAccount = nullAddress;
      this.currentNetworkId = null;
    },

    async checkIsCorrectNetwork() {
      if(!this.web3) return;
      const networkId = +await this.web3.eth.net.getId();
      if(process.env.NODE_ENV === 'development') {
        this.isWrongNetwork = !(networkId === 1337 || networkId === +getConfigValue('VUE_APP_NETWORK_ID'));
        return;
      }

      const env = window.location.href.startsWith('https://test') ? 'test' : 'production';
      const chains = (config as Config).environments[env].chains;
      for (const [chainName, values] of Object.entries(chains)) {
        if (+values.VUE_APP_NETWORK_ID === networkId) {
          localStorage.setItem('currentChain', chainName);
          this.currentChain = chainName;
          this.isWrongNetwork = false;
          break;
        }
        this.isWrongNetwork = true;
      }
    },

    async configureMetaMask(): Promise<boolean> {
      if(!this.web3) return false;
      const currentNetwork = await this.web3.eth.net.getId();
      this.defaultAccount = (await this.web3.eth.requestAccounts())[0];
      if(currentNetwork === +getConfigValue('VUE_APP_NETWORK_ID')) return false;
      return await this.configureChainNet({
        chainId: getConfigValue('chainId') || `0x${(1337).toString(16)}`,
        chainName: getConfigValue('VUE_APP_EXPECTED_NETWORK_NAME') || 'Ganache',
        currencyName: getConfigValue('currencyName') || 'Ether',
        currencySymbol: getConfigValue('currencySymbol') || 'ETH',
        currencyDecimals: +getConfigValue('currencyDecimals') || 18,
        rpcUrls: getConfigValue('rpcUrls') || ['http://127.0.0.1:7545/'],
        blockExplorerUrls: getConfigValue('blockExplorerUrls')
      });
    },

    async configureChainNet(
      { chainId, chainName, currencyName, currencySymbol, currencyDecimals, rpcUrls, blockExplorerUrls }:
      {
        chainId: string,
        chainName: string,
        currencyName: string,
        currencySymbol: string,
        currencyDecimals: number,
        rpcUrls: string[],
        blockExplorerUrls: string[]
      }): Promise<boolean>
    {
      if(!this.web3) return false;
      try {
        await (this.web3.currentProvider as any).request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
      } catch (switchError: any) {
        if(switchError.code === 4001) {
          console.error(switchError);
          return false;
        }
        try {
          await (this.web3.currentProvider as any).request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId,
                chainName,
                nativeCurrency: {
                  name: currencyName,
                  symbol: currencySymbol,
                  decimals: currencyDecimals,
                },
                rpcUrls,
                blockExplorerUrls,
              },
            ],
          });
        } catch (addError) {
          console.error(addError);
          return false;
        }
      }

      return true;
    },
  },
});

import { defineComponent } from 'vue';
import { secondsToDays } from '../../utils/date-time';
import { chainLogo } from '../../utils/chain-logo';
import { useLaunchpadStore } from '../../stores/launchpad';
import { useMainStore } from '../../stores/main';
import { mapState } from 'pinia';
import { ILaunch } from '../../interfaces/ILaunch';
import { fromWeiEther } from '../../utils/web3';
import ProgressBar from '../elements/ProgressBar.vue';
import BigNumber from 'bignumber.js';
import { defaultFromWeiEtherToNumberFixed } from '@/utils/math';
import { getImg } from '@/utils/common';

interface Data {
  timeUntilStart: number;
  timeUntilEnd: number;
  timeUpdateInterval: ReturnType<typeof setInterval> | null;
}

export default defineComponent({
  components: {
    ProgressBar,
    fromWeiEther
  },

  props: {
    launchId: {
      type: Number,
      required: true
    },
    isCompleted: {
      type: Boolean
    }
  },

  data() {
    return {
      timeUntilStart: 0,
      timeUntilEnd: 0,
      timeUpdateInterval: null
    } as Data;
  },

  computed: {
    ...mapState(useLaunchpadStore, ['launches', 'fundingPeriodPhase1', 'fundingPeriodPhase2', 'getLaunch']),
    ...mapState(useMainStore, ['currentChain']),
    saleStarted(): boolean {
      if(!this.launch?.startTime) return false;
      return this.timeUntilStart <= 0;
    },
    saleEnded(): boolean {
      if(!this.launch?.startTime) return false;
      return this.timeUntilEnd <= 0;
    },
    launch(): ILaunch {
      return this.getLaunch(this.launchId);
    },
    launchTotalRaisedInBothPhases(): BigNumber {
      const launchPhase2 = this.launches[this.launchId + 1];
      if(!launchPhase2) return this.launch.totalRaised;
      return this.launch.totalRaised.plus(+launchPhase2.totalRaised);
    },
    fundsToRaiseFormatted(): number {
      return defaultFromWeiEtherToNumberFixed(this.launch.fundsToRaise);
    },
    totalRaisedFormatted(): number {
      return defaultFromWeiEtherToNumberFixed(this.launch.totalRaised);
    },
    hasAnySocialMedia(): boolean {
      return !!(this.launch.twitterUrl ||
      this.launch.facebookUrl ||
      this.launch.discordUrl ||
      this.launch.redditUrl ||
      this.launch.telegramUrl ||
      this.launch.websiteUrl);
    }
  },

  methods: {
    fromWeiEther,
    secondsToDays,
    chainLogo,
    getImg,
    BigNumber
  },

  mounted() {
    this.timeUntilStart = this.launch?.startTime ? this.launch?.startTime - Math.floor(Date.now()/1000) : 0;
    this.timeUntilEnd = this.launch?.startTime ?
      this.timeUntilStart + (this.launch?.phase === 1 ? this.fundingPeriodPhase1 : this.fundingPeriodPhase2):
      0;
    this.timeUpdateInterval = setInterval(() => {
      if(this.timeUntilStart > 0) {
        this.timeUntilStart--;
      }
      if(this.timeUntilEnd > 0) {
        this.timeUntilEnd--;
      }
    }, 1000);
  },

  unmounted() {
    if(this.timeUpdateInterval) {
      clearInterval(this.timeUpdateInterval);
    }
  }

});


import { defineComponent } from 'vue';
import { chainLogo } from '../../utils/chain-logo';
import { secondsToDD, secondsToHH, secondsToMM, secondsToSS } from '../../utils/date-time';
import ProgressBar from '../elements/ProgressBar.vue';
import { mapState, mapActions } from 'pinia';
import { useLaunchpadStore } from '../../stores/launchpad';
import { useMainStore } from '../../stores/main';
import { fromWeiEther } from '../../utils/web3';
import { ILaunch } from '../../interfaces/ILaunch';
import { defaultFromWeiEtherToNumberFixed } from '../../utils/math';
import BigNumber from 'bignumber.js';
import { getImg } from '@/utils/common';

interface Data {
  timeUntilStart: number;
  timeUntilEnd: number;
  timeUpdateInterval: ReturnType<typeof setInterval> | null;
  launchProgressInterval: ReturnType<typeof setInterval> | null;
  userInvestment: number;
  totalUnclaimedCommittedValue: BigNumber;
  userUnclaimedCommittedValue: number;
}

export default defineComponent({
  components: {
    ProgressBar
  },

  props: {
    launchId: {
      type: Number,
      required: true
    },
  },

  data() {
    return {
      timeUntilStart: 0,
      timeUntilEnd: 0,
      timeUpdateInterval: null,
      launchProgressInterval: null,
      userInvestment: 0,
      totalUnclaimedCommittedValue: new BigNumber(0),
      userUnclaimedCommittedValue: 0
    } as Data;
  },

  computed: {
    ...mapState(useLaunchpadStore, ['fundingPeriodPhase1', 'fundingPeriodPhase2', 'getLaunch', 'commitStartOffset', 'commitTimeWindow',
      'unclaimedToAllowanceMultiplier', 'unclaimedAllocationPercentage']),
    ...mapState(useMainStore, ['currentChain']),
    saleStarted(): boolean {
      if(!this.launch?.startTime) return false;
      return this.timeUntilStart <= 0;
    },
    saleEnded(): boolean {
      if(!this.launch?.startTime) return false;
      return this.timeUntilEnd <= 0;
    },
    launch(): ILaunch {
      return this.getLaunch(this.launchId);
    },
    fundsToRaiseFormatted(): number {
      return defaultFromWeiEtherToNumberFixed(this.launch.fundsToRaise);
    },
    totalRaisedFormatted(): number {
      return defaultFromWeiEtherToNumberFixed(this.launch.totalRaised);
    },
    commitWindowActive(): boolean {
      if(!this.launch?.startTime) return false;
      return this.timeUntilStart <= this.commitStartOffset && this.timeUntilStart > this.commitTimeWindow;
    },
    userAllowanceFromUnclaimed(): number {
      return this.userUnclaimedCommittedValue * this.unclaimedToAllowanceMultiplier;
    },
    totalUnclaimedSkillCommitLimit(): number {
      return +(+fromWeiEther(this.launch.fundsToRaise.multipliedBy(this.unclaimedAllocationPercentage/100))).toFixed(2);
    },
  },

  methods: {
    secondsToDD,
    secondsToHH,
    secondsToMM,
    secondsToSS,
    chainLogo,
    fromWeiEther,
    getImg,
    BigNumber,
    ...mapActions(useLaunchpadStore, [
      'fetchLaunchProgress', 'fetchLaunchUserInvestment', 'fetchTotalUnclaimedCommittedValue', 'fetchUserUnclaimedCommittedValue'
    ]),

    async updateUserUnclaimedCommittedValue() {
      this.userUnclaimedCommittedValue = +(+fromWeiEther(await this.fetchUserUnclaimedCommittedValue(this.launchId))).toFixed(2);
    }
  },

  async mounted() {
    this.timeUntilStart = this.launch?.startTime ? this.launch?.startTime - Math.floor(Date.now()/1000) : 0;
    this.timeUntilEnd = this.launch?.startTime ?
      this.timeUntilStart + (this.launch?.phase === 1 ? this.fundingPeriodPhase1 : this.fundingPeriodPhase2):
      0;
    this.timeUpdateInterval = setInterval(() => {
      if(this.timeUntilStart > 0) {
        this.timeUntilStart--;
      }
      if(this.timeUntilEnd > 0) {
        this.timeUntilEnd--;
      }
    }, 1000);

    if(!this.saleStarted) {
      this.totalUnclaimedCommittedValue = await this.fetchTotalUnclaimedCommittedValue(this.launchId);
      await this.updateUserUnclaimedCommittedValue();
    }

    this.userInvestment = Math.floor(+fromWeiEther(await this.fetchLaunchUserInvestment(this.launchId)) * 100) / 100;

    this.launchProgressInterval = setInterval(async () => {
      await this.fetchLaunchProgress(this.launchId);
    }, 3000);
  },

  unmounted() {
    if(this.timeUpdateInterval) {
      clearInterval(this.timeUpdateInterval);
    }
    if(this.launchProgressInterval) {
      clearInterval(this.launchProgressInterval);
    }
  }

});

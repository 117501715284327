
import { defineComponent, inject } from 'vue';
import NavbarMain from './components/modules/NavbarMain.vue';
import { useMainStore } from './stores/main';
import { useLaunchpadStore } from './stores/launchpad';
import { useCryptoBladesStore } from './stores/cryptoblades';
import Web3 from 'web3';
import { NConfigProvider, GlobalThemeOverrides } from 'naive-ui';
import FooterMain from './components/modules/FooterMain.vue';

const themeOverrides: GlobalThemeOverrides = {
  Input: {
    color: '#010E1D',
    colorFocus: '#010E1D',
    textColor: '#FFFFFF',
    border: '#B9BFCC',
    borderHover: '#1168D0',
    borderFocus: '#1168D0',
    boxShadowFocus: '0 0 0 2px #1168D0',
    colorFocusWarning: '#010E1D',
    colorFocusError: '#010E1D'
  },
  Skeleton: {
    color: '#a5a7ad',
    colorEnd: '#696c6f'
  },
  Tabs: {
    barColor: '#1168D0',
    tabGapMediumBar: '20px'
  }
};

export default defineComponent({
  components: {
    NavbarMain,
    NConfigProvider,
    FooterMain,
  },

  data() {
    return {
      themeOverrides
    };
  },

  async mounted() {
    const mainStore = useMainStore();
    const launchpadStore = useLaunchpadStore();
    const cryptoBladesStore = useCryptoBladesStore();
    const web3 = inject('web3') as Web3;
    await mainStore.initialize(web3);
    mainStore.checkIfConnected();
    try {
      await launchpadStore.fetchVars();
      await launchpadStore.fetchAllLaunchDetails();
      await Promise.all([
        cryptoBladesStore.fetchUnclaimedSkillBalance(),
        launchpadStore.fetchVestings()
      ]);
    }
    catch (err) {
      console.error('Check if you are connected to the correct network!');
      console.error(err);
    }
  }
});

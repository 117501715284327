import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_progress = _resolveComponent("n-progress")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.showExternalBorder ? 'border-8 border-solid border-dark-3' : '')
  }, [
    _createVNode(_component_n_progress, {
      type: "line",
      percentage: _ctx.value.multipliedBy(100).dividedBy(_ctx.max).toNumber(),
      height: 10,
      "border-radius": _ctx.borderRadius || 0,
      "show-indicator": false,
      "rail-color": "#2F3A46",
      color: "#1168D0"
    }, null, 8, ["percentage", "border-radius"])
  ], 2))
}

import { defineComponent } from 'vue';
import ProjectFeaturedCard from '../info/ProjectFeaturedCard.vue';
import { useLaunchpadStore } from '../../stores/launchpad';
import { mapState } from 'pinia';

export default defineComponent({
  components: {
    ProjectFeaturedCard
  },

  computed: {
    ...mapState(useLaunchpadStore, ['featuredLaunchId', 'getLaunch']),
  }
});

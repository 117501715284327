
import { defineComponent } from 'vue';
import philipImg from '../../assets/team/philip.png';
import nickImg from '../../assets/team/nick.png';
import davidImg from '../../assets/team/david.png';
import isabelImg from '../../assets/team/isabel.png';
import danImg from '../../assets/team/dan.png';
import kyleImg from '../../assets/team/kyle.png';

export default defineComponent({
  data() {
    return {
      philipImg,
      nickImg,
      davidImg,
      isabelImg,
      danImg,
      kyleImg
    };
  }
});

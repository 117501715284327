import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import './index.css';
import naive from 'naive-ui';
import getRouter from './router';
import Web3 from 'web3';

export const router = getRouter();

const web3 = new Web3(Web3.givenProvider || process.env.VUE_APP_WEB3_FALLBACK_PROVIDER);

createApp(App).use(router).use(createPinia()).use(naive).provide('web3', web3).mount('#app');

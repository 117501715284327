
import { defineComponent } from 'vue';
import { chainLogo } from '../../utils/chain-logo';
import ProjectCard from '../info/ProjectCard.vue';
import ApplyForIncubationCard from '../modules/ApplyForIncubationCard.vue';
import { useMainStore } from '../../stores/main';
import { useLaunchpadStore } from '../../stores/launchpad';
import { mapState, mapActions } from 'pinia';


export default defineComponent({
  components: {
    ProjectCard,
    ApplyForIncubationCard
  },
  data() {
    return {
      chainLogo,
      showCompleted: false
    };
  },

  computed: {
    ...mapState(useMainStore, ['currentChain']),
    ...mapState(useLaunchpadStore, ['launches', 'upcomingLaunchIds', 'completedLaunchIds']),
  },

  methods: {
    ...mapActions(useMainStore, ['setCurrentChain']),
    toggleShowCompleted() {
      this.showCompleted = !this.showCompleted;
    },
    async setChain(currentChain: string) {
      await this.setCurrentChain(currentChain);
    }
  }
});


import { defineComponent } from 'vue';
import BigNumber from 'bignumber.js';

export default defineComponent({
  props: {
    value: {
      type: BigNumber,
      required: true
    },
    max: {
      type: BigNumber,
      required: true
    },
    borderRadius: {
      type: Number
    },
    showExternalBorder: {
      type: Boolean,
      default: true
    }
  }
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-background1 bg-cover bg-[center_top_-3em] lg:bg-[center_top_-6em] 2xl:bg-[center_top_-10em]" }
const _hoisted_2 = { class: "bg-dark-1/70" }
const _hoisted_3 = { class: "bg-dark-1 flex justify-center -mt-40 md:border-t border-b border-dark-border-2" }
const _hoisted_4 = { class: "px-6 md:px-0 md:w-5/6 max-w-6xl mt-64 mb-20" }
const _hoisted_5 = { class: "bg-background2 bg-no-repeat bg-cover bg-right md:bg-right-top" }
const _hoisted_6 = { class: "flex justify-center bg-dark-2/70 md:pb-28" }
const _hoisted_7 = { class: "bg-dark-1 flex justify-center border-t md:border-b border-dark-border-2 -mt-28 md:mt-0" }
const _hoisted_8 = { class: "w-full md:w-5/6 max-w-6xl mb-5" }
const _hoisted_9 = { class: "bg-dark-1 flex justify-center" }
const _hoisted_10 = { class: "w-full px-6 md:px-0 md:w-5/6 max-w-6xl mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LaunchpadTop = _resolveComponent("LaunchpadTop")!
  const _component_HowToParticipate = _resolveComponent("HowToParticipate")!
  const _component_UpcomingIdos = _resolveComponent("UpcomingIdos")!
  const _component_OurTeamCard = _resolveComponent("OurTeamCard")!
  const _component_ApplyForIncubationCard = _resolveComponent("ApplyForIncubationCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LaunchpadTop, { class: "pt-36 md:pt-52" })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_HowToParticipate)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_UpcomingIdos, { class: "mt-20 -pb-16 max-w-6xl w-full md:w-5/6" })
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_OurTeamCard, { class: "mt-20 mb-72" })
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_ApplyForIncubationCard, { class: "-mt-48 md:-mt-40" })
      ])
    ])
  ]))
}
export const copyToClipboard = (text: string): void => {
  if (!navigator.clipboard){
    const dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  } else{
    navigator.clipboard.writeText(text);
  }
};

export function getImg(img: string) {
  return require(`../assets/launch-details/${img}`);
}
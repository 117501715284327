
import { defineComponent, PropType } from 'vue';
import { mapState, mapActions } from 'pinia';
import { useLaunchpadStore } from '../../stores/launchpad';
import { ILaunch } from '../../interfaces/ILaunch';
import { fromWeiEther } from '../../utils/web3';
import { ILinearVestingInfo } from '@/interfaces/IVestingInfo';
import { getImg } from '@/utils/common';

interface Data {
  isClaiming: boolean;
  availableClaimAmount: number;
  totalUserInvestment: number;
  updateInterval: ReturnType<typeof setInterval> | null;
}

export default defineComponent({
  props: {
    launchId: {
      type: Number,
      required: true
    },
    vestingInfo: {
      type: Object as PropType<ILinearVestingInfo>,
      required: true
    }
  },

  data() {
    return {
      fromWeiEther,
      isClaiming: false,
      availableClaimAmount: 0,
      totalUserInvestment: 0,
      updateInterval: null
    } as Data;
  },

  computed: {
    ...mapState(useLaunchpadStore, ['getLaunch']),
    claimStartDate(): string {
      if(!this.vestingInfo.vestingStartTimestamp) return '';
      const date = new Date(this.vestingInfo.vestingStartTimestamp*1000);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    launch(): ILaunch {
      return this.getLaunch(this.launchId);
    },
    vestingDuration(): string {
      return (this.vestingInfo.vestingDuration / (60 * 60 * 24)).toFixed();
    },
    claimedAll(): boolean {
      return this.totalUserInvestment > 0 && this.availableClaimAmount === 0;
    }
  },

  methods: {
    getImg,
    ...mapActions(useLaunchpadStore, ['claimTokensLinear', 'fetchLinearClaimAmount', 'fetchTotalUserInvestment']),

    async onClaimTokens() {
      try {
        this.isClaiming = true;
        await this.claimTokensLinear(this.launchId);
        await this.updatedAvailableClaimAmount();
      }
      finally {
        this.isClaiming = false;
      }
    },

    async updatedAvailableClaimAmount() {
      this.availableClaimAmount = +(+fromWeiEther(await this.fetchLinearClaimAmount(this.launchId))).toFixed(2);
    }
  },

  async mounted() {
    this.totalUserInvestment = +await this.fetchTotalUserInvestment(this.launchId);
    await this.updatedAvailableClaimAmount();
    this.updateInterval = setInterval(async () => {
      await this.updatedAvailableClaimAmount();
    }, 10000);
  },

  unmounted() {
    if(this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  }
});

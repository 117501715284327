import launchpadJson from '../build/contracts/Launchpad.json';
import skillTokenJson from '../build/contracts/SkillToken.json';
import cryptoBladesJson from '../build/contracts/CryptoBlades.json';
import type {
  CryptoBlades,
  IERC20, Launchpad
} from '../build/abi-interfaces';

import Web3 from 'web3';
import { Contract as Web3EthContract } from 'web3-eth-contract';
import config from '../app-config.json';

interface TypeSafeContract<Abi> {
  methods: Abi;
}

export type Abi = any[];
export type Networks = Partial<Record<string, { address: string }>>;
export type Contract<Abi> = Omit<Web3EthContract, 'methods'> & TypeSafeContract<Abi>;

export interface Contracts {
  SkillToken: Contract<IERC20>;
  Launchpad: Contract<Launchpad>;
  CryptoBlades: Contract<CryptoBlades>;
}

interface Config {
  environments: Record<string, Chain>;
}

interface Chain {
  chains: Record<string, Record<string, any>>;
}

export function getConfigValue(key: string): any {
  if (process.env.VUE_APP_STAGE === 'alpha') {
    return process.env[key];
  }

  if(process.env.NODE_ENV === 'development') return '';
  const env = window.location.href.startsWith('https://test') ? 'test' : 'production';
  const chain = localStorage.getItem('currentChain') || 'BNB';
  return (config as Config).environments[env].chains[chain][key];
}

export async function setUpContracts(web3: Web3): Promise<Contracts> {
  const networkId = getConfigValue('VUE_APP_NETWORK_ID') || '5777';

  const skillTokenAddress = getConfigValue('VUE_APP_SKILL_TOKEN_CONTRACT_ADDRESS') || (skillTokenJson.networks as Networks)[networkId]!.address;
  const SkillToken = new web3.eth.Contract(skillTokenJson.abi as Abi, skillTokenAddress);

  const launchpadContractAddr = getConfigValue('VUE_APP_LAUNCHPAD_CONTRACT_ADDRESS') || (launchpadJson.networks as Networks)[networkId]!.address;
  const Launchpad = new web3.eth.Contract(launchpadJson.abi as Abi, launchpadContractAddr);

  const cryptoBladesContractAddr = getConfigValue('VUE_APP_CRYPTOBLADES_CONTRACT_ADDRESS') || (cryptoBladesJson.networks as Networks)[networkId]!.address;
  const CryptoBlades = new web3.eth.Contract(cryptoBladesJson.abi as Abi, cryptoBladesContractAddr);

  return {
    SkillToken,
    Launchpad,
    CryptoBlades
  };
}
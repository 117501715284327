
import { defineComponent } from 'vue';
import ProjectDetailsCard from '../info/ProjectDetailsCard.vue';
import ApplyForIncubationCard from '../modules/ApplyForIncubationCard.vue';
import { useLaunchpadStore } from '../../stores/launchpad';
import { mapState } from 'pinia';
import { ILaunch } from '../../interfaces/ILaunch';
import { nullAddress } from '../../utils/web3';
import { fromWeiEther } from '../../utils/web3';
import { copyToClipboard, getImg } from '../../utils/common';

let scrollHandler: () => void;

export default defineComponent({
  components: {
    ProjectDetailsCard,
    ApplyForIncubationCard
  },
  data() {
    return {
      nullAddress,
      fromWeiEther,
      copyToClipboard
    };
  },

  computed: {
    ...mapState(useLaunchpadStore, ['getLaunch']),
    launchId(): number {
      return +this.$route.params.id;
    },
    launch(): ILaunch {
      return this.getLaunch(this.launchId);
    }
  },

  mounted() {
    let sections = document.querySelectorAll('#summary,#comparison,#tokenomics,#roadmap');
    let sectionNavs = document.querySelectorAll('.section-nav');
    scrollHandler = () => {
      if(!sections.length) {
        sections = document.querySelectorAll('#summary,#comparison,#tokenomics,#roadmap');
        sectionNavs = document.querySelectorAll('.section-nav');
      }
      let current = '';
      sections.forEach((section) => {
        const sectionTop = (section as HTMLElement).offsetTop;
        const sectionHeight = section.clientHeight;
        if (scrollY >= sectionTop - sectionHeight / 3 || section === sections[0]) {
          current = section.getAttribute('id') || '';
        }
      });

      sectionNavs.forEach((nav) => {
        nav.classList.remove('text-main-blue');
        if (current && nav.classList.contains(current)) {
          nav.classList.add('text-main-blue');
        }
      });
    };

    document.addEventListener('scroll', scrollHandler, { passive: true });
  },

  methods: {
    getImg
  },

  unmounted() {
    document.removeEventListener('scroll', scrollHandler);
  }
});

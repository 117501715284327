
import { defineComponent } from 'vue';
import PeriodicClaimCard from './PeriodicClaimCard.vue';
import LinearClaimCard from './LinearClaimCard.vue';
import { mapState, mapActions } from 'pinia';
import { useLaunchpadStore } from '../../stores/launchpad';
import { useMainStore } from '../../stores/main';
import { nullAddress } from '@/utils/web3';

export default defineComponent({
  components: {
    PeriodicClaimCard,
    LinearClaimCard
  },

  props: {
    isLinear: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(useLaunchpadStore, ['linearVestings', 'sortedPeriodicVestings', 'sortedLinearVestingsIds']),
    ...mapState(useMainStore, ['defaultAccount']),
  },

  methods: {
    ...mapActions(useLaunchpadStore, ['fetchVestings']),
  },

  watch: {
    async defaultAccount(newValue: string, oldValue: string) {
      if(oldValue !== nullAddress && newValue !== nullAddress) {
        await this.fetchVestings();
      }
    },
  },
});


import { defineComponent } from 'vue';
import { chainLogo } from '../../utils/chain-logo';
import { secondsToDD, secondsToHH, secondsToMM, secondsToSS } from '../../utils/date-time';
import ProgressBar from '../elements/ProgressBar.vue';
import { mapState, mapActions } from 'pinia';
import { useLaunchpadStore } from '../../stores/launchpad';
import { useMainStore } from '../../stores/main';
import { useCryptoBladesStore } from '../../stores/cryptoblades';
import { ILaunch } from '../../interfaces/ILaunch';
import { fromWeiEther, toWeiEther } from '../../utils/web3';
import { defaultFromWeiEtherToNumberFixed } from '../../utils/math';
import BigNumber from 'bignumber.js';
import { getImg } from '@/utils/common';
import Web3 from 'web3';


interface Data {
  timeUntilStart: number;
  timeUntilEnd: number;
  timeUpdateInterval: ReturnType<typeof setInterval> | null;
  launchProgressInterval: ReturnType<typeof setInterval> | null;
  isApproving: boolean;
  isApproved: boolean;
  isModalBusy: boolean;
  allocation: BigNumber;
  showModal: boolean;
  modalAmount: number;
  isUserWhitelisted: boolean;
  fundingTokenBalance: number;
  skillPrice: number;
  totalUnclaimedCommittedValue: BigNumber;
  userUnclaimedCommittedValue: number;
  isLoading: boolean;
  userInvestment: number;
  targetCommitWallet: string;
  targetWalletWhitelisted: boolean;
}

export default defineComponent({
  components: {
    ProgressBar
  },

  props: {
    launchId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      timeUntilStart: 0,
      timeUntilEnd: 0,
      timeUpdateInterval: null,
      launchProgressInterval: null,
      isApproving: false,
      isApproved: false,
      isModalBusy: false,
      allocation: new BigNumber(0),
      showModal: false,
      modalAmount: 0,
      isUserWhitelisted: false,
      fundingTokenBalance: 0,
      skillPrice: 0,
      totalUnclaimedCommittedValue: new BigNumber(0),
      userUnclaimedCommittedValue: 0,
      isLoading: false,
      userInvestment: 0,
      targetCommitWallet: '',
      targetWalletWhitelisted: true
    } as Data;
  },

  computed: {
    ...mapState(useLaunchpadStore,
      [
        'fundingPeriodPhase1',
        'fundingPeriodPhase2',
        'unclaimedToAllowanceMultiplier',
        'unclaimedAllocationPercentage',
        'commitStartOffset',
        'commitTimeWindow',
        'getLaunch'
      ]),
    ...mapState(useCryptoBladesStore, ['unclaimedSkillBalance']),
    ...mapState(useMainStore, ['defaultAccount', 'currentChain']),

    saleStarted(): boolean {
      if(!this.launch?.startTime) return false;
      return this.timeUntilStart <= 0;
    },
    saleEnded(): boolean {
      if(!this.launch?.startTime) return false;
      return this.timeUntilEnd <= 0;
    },
    commitWindowActive(): boolean {
      if(!this.launch?.startTime) return false;
      return this.timeUntilStart <= this.commitStartOffset && this.timeUntilStart > this.commitStartOffset - this.commitTimeWindow;
    },
    timeUntilCommittingStarts(): number {
      return this.timeUntilStart - this.commitStartOffset;
    },
    timeUntilCommittingEnds(): number {
      return this.timeUntilStart - (this.commitStartOffset - this.commitTimeWindow);
    },
    launch(): ILaunch {
      return this.getLaunch(this.launchId);
    },
    maxAllocationFormatted(): number {
      return Math.floor(+fromWeiEther(new BigNumber(this.allocation)) * 100) / 100;
    },
    unclaimedSkillBalanceFormatted(): number {
      return Math.floor(+fromWeiEther(new BigNumber(this.unclaimedSkillBalance)) * 1000) / 1000;
    },
    isCommitModal(): boolean {
      return !this.saleStarted;
    },
    unclaimedToAllowanceRatio(): number {
      return +(this.skillPrice * this.unclaimedToAllowanceMultiplier).toFixed(2);
    },
    userAllowanceFromUnclaimed(): number {
      return this.userUnclaimedCommittedValue * this.unclaimedToAllowanceMultiplier;
    },
    totalUnclaimedSkillCommitLimit(): number {
      return +(+fromWeiEther(this.launch.fundsToRaise.multipliedBy(this.unclaimedAllocationPercentage/100))).toFixed(2);
    },
    maxPossibleCommitment(): number {
      return Math.min(
        this.unclaimedSkillBalanceFormatted,
        +fromWeiEther(this.launch.fundsToRaise.multipliedBy(this.unclaimedAllocationPercentage/100).minus(this.totalUnclaimedCommittedValue)
          .div(this.skillPrice * this.unclaimedToAllowanceMultiplier)).toString()
      );
    },
    maxPossibleInvestment(): number {
      return Math.min(
        this.fundingTokenBalance,
        this.maxAllocationFormatted,
        this.fundsToRaiseFormatted - this.totalRaisedFormatted
      );
    },
    fundsToRaiseFormatted(): number {
      return defaultFromWeiEtherToNumberFixed(this.launch.fundsToRaise);
    },
    totalRaisedFormatted(): number {
      return defaultFromWeiEtherToNumberFixed(this.launch.totalRaised);
    },
    canCommit(): boolean {
      return this.totalUnclaimedCommittedValue.lt(this.launch.fundsToRaise.multipliedBy(this.unclaimedAllocationPercentage/100))
        && this.modalAmount <= this.maxPossibleCommitment && this.modalAmount > 0 && (this.isTargetWalletValid || this.isUserWhitelisted);
    },
    canInvest(): boolean {
      return this.isCommitModal || (this.modalAmount <= this.maxPossibleInvestment
        && this.modalAmount > 0);
    },
    isModalAmountValid(): boolean {
      return ((!this.isCommitModal && this.modalAmount <= this.maxPossibleInvestment) ||
        (this.isCommitModal && this.modalAmount <= this.maxPossibleCommitment)) &&
        this.modalAmount >= 0.01;
    },
    isTargetWalletValid(): boolean {
      return Web3.utils.isAddress(this.targetCommitWallet);
    },
    isSoldOut(): boolean {
      return this.fundsToRaiseFormatted > 0 && this.fundsToRaiseFormatted - this.totalRaisedFormatted === 0;
    }
  },

  watch: {
    async defaultAccount() {
      await this.fetchUserInfo();
    },
  },

  methods: {
    secondsToDD,
    secondsToHH,
    secondsToMM,
    secondsToSS,
    chainLogo,
    fromWeiEther,
    getImg,
    BigNumber,
    ...mapActions(useLaunchpadStore,
      [
        'approveERC20Spend',
        'hasEnoughAllowance',
        'getAllocation',
        'invest',
        'fetchLaunchProgress',
        'fetchIsCurrentUserWhitelisted',
        'commitUnclaimedSkill',
        'fetchFundingTokenBalance',
        'fetchSkillPrice',
        'fetchTotalUnclaimedCommittedValue',
        'fetchUserUnclaimedCommittedValue',
        'fetchLaunchUserInvestment',
        'fetchIsUserWhitelisted'
      ]),
    ...mapActions(useCryptoBladesStore, ['fetchUnclaimedSkillBalance']),

    async approveERC20() {
      try {
        this.isApproving = true;
        await this.approveERC20Spend(this.launchId, this.allocation.toString());
        this.isApproved = await this.hasEnoughAllowance(this.launchId);
      }
      finally {
        this.isApproving = false;
      }
    },

    openModal() {
      if(this.isCommitModal) {
        this.modalAmount = this.maxPossibleCommitment;
      }
      else {
        this.modalAmount = this.maxPossibleInvestment;
      }
      this.showModal = true;
    },

    async onInvest() {
      try {
        this.isModalBusy = true;
        await this.invest(this.launchId, toWeiEther(this.modalAmount.toString()));
        [this.allocation, this.userInvestment, this.fundingTokenBalance] = await Promise.all([
          this.getAllocation(this.launchId),
          Math.floor(+fromWeiEther(await this.fetchLaunchUserInvestment(this.launchId)) * 100) / 100,
          Math.floor(+fromWeiEther(await this.fetchFundingTokenBalance(this.launchId)) * 100) / 100
        ]);
        this.showModal = false;
      }
      finally {
        this.isModalBusy = false;
      }
    },

    async onCommit() {
      try {
        this.isModalBusy = true;
        this.targetWalletWhitelisted = true;
        if(!this.isUserWhitelisted) {
          this.targetWalletWhitelisted = await this.fetchIsUserWhitelisted(this.targetCommitWallet, this.launchId);
        }
        if(this.targetWalletWhitelisted) {
          await this.commitUnclaimedSkill(this.launchId, toWeiEther(this.modalAmount.toString()), this.targetCommitWallet);
          await Promise.all([
            this.fetchUnclaimedSkillBalance(),
            this.updateUserUnclaimedCommittedValue()
          ]);
          this.modalAmount = this.maxPossibleCommitment;
        }
      }
      finally {
        this.isModalBusy = false;
      }
    },

    async fetchUserInfo() {
      [
        this.isUserWhitelisted,
        this.isApproved,
        this.allocation,
        this.userInvestment,
        this.fundingTokenBalance,
        this.skillPrice
      ] = await Promise.all([
        this.fetchIsCurrentUserWhitelisted(this.launchId),
        this.hasEnoughAllowance(this.launchId),
        this.getAllocation(this.launchId),
        Math.floor(+fromWeiEther(await this.fetchLaunchUserInvestment(this.launchId)) * 100) / 100,
        Math.floor(+fromWeiEther(await this.fetchFundingTokenBalance(this.launchId)) * 100) / 100,
        Math.floor(+fromWeiEther(await this.fetchSkillPrice()) * 100) / 100,
        this.fetchUnclaimedSkillBalance(),
        this.updateUserUnclaimedCommittedValue()
      ]);
    },

    async updateUserUnclaimedCommittedValue() {
      this.userUnclaimedCommittedValue = +(+fromWeiEther(await this.fetchUserUnclaimedCommittedValue(this.launchId))).toFixed(2);
    }
  },

  async mounted() {
    this.timeUntilStart = this.launch?.startTime ? this.launch?.startTime - Math.floor(Date.now()/1000) : 0;
    this.timeUntilEnd = this.launch?.startTime ?
      this.timeUntilStart + (this.launch?.phase === 1 ? this.fundingPeriodPhase1 : this.fundingPeriodPhase2):
      0;
    this.timeUpdateInterval = setInterval(() => {
      if(this.timeUntilStart > 0) {
        this.timeUntilStart--;
      }
      if(this.timeUntilEnd > 0) {
        this.timeUntilEnd--;
      }
    }, 1000);

    try {
      this.isLoading = true;
      await this.fetchUserInfo();
    }
    finally {
      this.isLoading = false;
    }
    if(!this.saleStarted) {
      this.totalUnclaimedCommittedValue = await this.fetchTotalUnclaimedCommittedValue(this.launchId);
      await this.updateUserUnclaimedCommittedValue();
    }

    this.launchProgressInterval = setInterval(async () => {
      if(this.saleStarted) {
        await this.fetchLaunchProgress(this.launchId);
      } else {
        this.totalUnclaimedCommittedValue = await this.fetchTotalUnclaimedCommittedValue(this.launchId);
      }
    }, 3000);
  },

  unmounted() {
    if(this.timeUpdateInterval) {
      clearInterval(this.timeUpdateInterval);
    }
    if(this.launchProgressInterval) {
      clearInterval(this.launchProgressInterval);
    }
  }

});
